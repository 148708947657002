.VoucherUploader {

    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;

    .text-align-left {
        text-align: left;
    }
}