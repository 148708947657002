.entry-page-existing-documents > h1 {
    text-align: center;
    font-size: 1.3rem;
    margin: 1.2rem 0 0.7rem 0;
}

.entry-page-existing-documents_vouchers-grid {
    display: flex;
}

.empty-document-card {
    padding: 2rem;
    cursor: pointer;
}

.page-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.ActionButtons {
    display: flex;
    justify-content: space-around;
    gap: 1rem;
}

.ActionButtons button {
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid rgb(48, 5, 148);
    color: rgb(48, 5, 148);
    flex: 1;
    text-align: center;
}
